@media print {
    body {
        /*font-family: georgia, times, serif;*/
        width: 100%;
        max-width: none;
    }
    section,
    .pageHead {
        width: 100%;
        max-width: none;
    }

    div.custBox.content {
        border-color: #777 !important;
        padding: 0px;
        margin: 0px;
    }

    div.usageReport {
        border: 1px solid #777 !important;
        padding: 0px;
        margin: 0px;
    }
    div.usage {
        width: 100%;
        max-width: none;
        padding: 0px;
        margin: 0px;
    }

    div.pageContent,
    div.container,
    div.content{
        width: 100%;
        max-width: none;
        padding: 0px;
        margin: 0px;
    }

    .donotPrint {
        display: none;
        padding: 0px;
        margin: 0px;
    }

    .medPrint {
        font-size: 90% !important;
    }

    .smallPrint {
        font-size: 80% !important;
    }

    div.interval.ROTooltip {
        display: none;
    }

    span.interval {
        font-size: 80% !important;
    }

    div.custName {
        font-size: 80% !important;
    }

    /* section.pageHead {
        display: none;
    } */
    /* 
    
    div.pageFunctions,
    div.subHeader,
    div.serviceBox {
        display: none;
    } */

    /*
    div.pageContent,
    div.pageContent.container {
        width: 100%;
        max-width: none;
    }
    */
}
